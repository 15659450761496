import {
  Button,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Modal,
  ModalVariant,
  TextArea,
} from "@patternfly/react-core";
import { useContext, useState } from "react";
import "./style.scss";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { submitVoFFeedback } from "../../../pages/RHSCAssistant/apis";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const VOFFeedback = ({ isShowFeedbackModal, toggleFeedbackModal }) => {
  const alert = useContext(AlertContext);
  const { user } = useAuthContext();
  const [formValues, setFormValues] = useState({
    salesProcessStage: "General",
    feedbackCategory: "Enhancement",
    feedback: "",
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] =
    useState<boolean>(false);

  const salesProcessStageOptions = [
    { value: "please choose", label: "Select one", disabled: true },
    {
      value: "Opportunity Management",
      label: "Opportunity Management",
      disabled: false,
    },
    {
      value: "Account Management",
      label: "Account Management",
      disabled: false,
    },
    {
      value: "Lead and Contact Management",
      label: "Lead and Contact Management",
      disabled: false,
    },
    { value: "Quoting", label: "Quoting", disabled: false },
    {
      value: "Contract Lifecycle Management (Docusign)",
      label: "Contract Lifecycle Management (Docusign)",
      disabled: false,
    },
  ];

  const feedbackCategoryOptions = [
    { value: "please choose", label: "Select one", disabled: true },
    { value: "Enhancement", label: "Enhancement", disabled: false },
    {
      value: "New Idea",
      label: "New Idea",
      disabled: false,
    },
    {
      value: "Process Improvement",
      label: "Process Improvement",
      disabled: false,
    },
    { value: "Other", label: "Other", disabled: false },
  ];

  const onSubmitVOFFeedback = () => {
    setIsSubmitDisabled(true);

    let isValidated = Object.entries(formValues).every(([, value]) => {
      return Object.keys(value).length !== 0;
    });

    if (!isValidated) {
      alert.addAlert("Fill all the required fields", "danger");
      setIsSubmitDisabled(false);
      return;
    }

    if (formValues.feedback.length > 200) {
      alert.addAlert("Feedback can't be more than 200 characters.", "danger");
      setIsSubmitDisabled(false);
      return;
    }

    submitVoFFeedback({
      endpoint: "/vof/feedback",
      dataToSend: {
        ...formValues,
        userInfo: {
          userEmail: user?.email,
          userName: user?.name,
        },
      },
      successCallback: () => {
        setIsFeedbackSubmitted(true);
      },
      failureCallback: () => {
        setIsSubmitDisabled(false);
        alert.addAlert("Something went wrong.", "danger");
      },
    });
  };

  const onClose = () => {
    setFormValues({
      salesProcessStage: "General",
      feedbackCategory: "Enhancement",
      feedback: "",
    });
    setIsFeedbackSubmitted(false);
    setIsSubmitDisabled(false);
    toggleFeedbackModal();
  };

  return (
    <Modal
      variant={ModalVariant.small}
      title="RHSC Voice of the Field"
      isOpen={isShowFeedbackModal}
      onClose={onClose}
      actions={
        isFeedbackSubmitted
          ? [
              <Button
                className="feedback-modal-action-button"
                key="close"
                variant="primary"
                onClick={onClose}
              >
                Close
              </Button>,
            ]
          : [
              <Button
                className="feedback-modal-action-button"
                key="confirm"
                variant="primary"
                isDisabled={isSubmitDisabled}
                isLoading={isSubmitDisabled}
                onClick={onSubmitVOFFeedback}
              >
                {isSubmitDisabled ? "Submitting..." : "Submit"}
              </Button>,
              <Button
                className="feedback-modal-action-button"
                key="cancel"
                variant="link"
                onClick={onClose}
              >
                Cancel
              </Button>,
            ]
      }
    >
      <div className="rhsc-assistant-vof-feedback">
        {isFeedbackSubmitted ? (
          <p className="feedback-submission-text">
            Thank you for submitting your submission. A member of the Red Hat
            Sales Cloud program team will be in touch with you soon.
          </p>
        ) : (
          <Form>
            <FormGroup
              label="Which part of the sales process does your feedback relate to?"
              isRequired
              fieldId="sales-process-stage"
            >
              <FormSelect
                value={formValues.salesProcessStage}
                onChange={(_event, value) => {
                  setFormValues({ ...formValues, salesProcessStage: value });
                }}
                aria-label="SalesProcessStage Input"
                ouiaId="SalesProcessStageSelect"
                id="salesProcessStage"
              >
                {salesProcessStageOptions.map((option, index) => (
                  <FormSelectOption
                    isDisabled={option.disabled}
                    key={index}
                    value={option.value}
                    label={option.label}
                  />
                ))}
              </FormSelect>
            </FormGroup>

            <FormGroup
              label="Which of the following best categorizes the feedback being submitted?"
              isRequired
              fieldId="feedback-category"
            >
              <FormSelect
                value={formValues.feedbackCategory}
                onChange={(_event, value) => {
                  setFormValues({ ...formValues, feedbackCategory: value });
                }}
                aria-label="FeedbackCategory Input"
                ouiaId="FeedbackCategorySelect"
                id="feedbackCategory"
              >
                {feedbackCategoryOptions.map((option, index) => (
                  <FormSelectOption
                    isDisabled={option.disabled}
                    key={index}
                    value={option.value}
                    label={option.label}
                  />
                ))}
              </FormSelect>
            </FormGroup>

            <FormGroup
              label="We are always looking to improve, ideate, and innovate for our future state. Enter your feedback to support our mission."
              fieldId="feedback"
              isRequired
            >
              <TextArea
                value={formValues.feedback}
                placeholder="Type your feedback (upto 200 characters)"
                onChange={(_event, value) => {
                  if (value.length <= 200) {
                    setFormValues({ ...formValues, feedback: value });
                  } else {
                    setFormValues({
                      ...formValues,
                      feedback: value.substring(0, 200),
                    });
                  }
                }}
                id="feedback"
                name="feedback"
              />
            </FormGroup>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default VOFFeedback;
